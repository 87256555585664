












import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

@Component({
  components: {
    DoughnutChart: () => import('@/lib/charts/doughnutChart'),
  },
})
export default class StatusPieCharts extends Vue {
  @Prop({ required: true }) cardData!: {
    labels: string[],
    data: number[],
    colors: string[],
    name: string,
  };

  chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    events: ['click'],
    loaded: false,
    legend: {
      display: false,
    },
    cutoutPercentage: 72,
  };

  chartData = {
    labels: this.cardData.labels,
    datasets: [{
      backgroundColor: this.cardData.colors,
      hoverBackgroundColor: this.cardData.colors,
      borderColor: this.cardData.colors,
      hoverBorderColor: '#FFFFFF',
      hoverBorderWidth: 3,
      borderWidth: 1,
      borderAlign: 'inner',
      data: this.cardData.data,
    }],
  };

  @Watch('cardData', {
    deep: true,
  })
  onCardDataChange() {
    if (this.$refs.doughnutChart) {
      this.chartData.datasets[0].data = this.cardData.data;
      (this.$refs.doughnutChart as any).$data._chart.update();
    }
  }
}
